import {Button, Icon} from '@trustle/component-library';
import React, {useMemo, useState} from 'react';
import {FiltersSidePanel} from '../filters-side-panel/FiltersSidePanel';
import {useUserFilters} from '../../UserFiltersContext';

export function AdvancedSearchButton() {
  const {filters} = useUserFilters();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const filtersCount = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {page: _page, size: _size, group_by: _group_by, ...restFilters} = filters;

    return Object.keys(restFilters).filter((value) => value).length;
  }, [filters]);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <div>
      <Button variant="secondary" onClick={toggleDrawer}>
        <span className="tr-flex tr-gap-1 tr-items-center">
          <Icon type="filter" />
          Advanced Search
          {filtersCount > 0 && <strong className="tr-text-trustle-navy">({filtersCount})</strong>}
        </span>
      </Button>
      <FiltersSidePanel isOpen={isDrawerOpen} onClose={toggleDrawer} />
    </div>
  );
}
