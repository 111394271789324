import React, {PropsWithChildren, useEffect, useState} from 'react';
import {USER_STATUSES} from '../../constants';
import _ from 'lodash';
import {useUserFilters} from '../../UserFiltersContext';
import {UserStatus} from '../../types';

const CustomTabButton = ({
  children,
  onClick,
  selected = false,
}: PropsWithChildren<{onClick: () => void; selected?: boolean}>) => {
  const wrapperClassName = `tr-flex tr-items-center tr-py-3 tr-px-8 tr-cursor-pointer ${
    selected ? 'tr-text-trustle-navy' : 'tr-text-gray-400'
  }`;
  return (
    <div
      className={wrapperClassName}
      role="button"
      onClick={onClick}
      style={
        selected
          ? {
              borderBottom: '2px solid #00519B',
            }
          : {}
      }
    >
      {children}
    </div>
  );
};

export function StatusQuickFilter() {
  const {filters, setFilter} = useUserFilters();
  const [selectedStatus, setSelectedStatus] = useState<UserStatus | undefined>();

  useEffect(() => {
    const {status} = filters;
    setSelectedStatus(status);
  }, [filters]);

  return (
    <div className="tr-flex tr-items-center tr-py-3">
      {USER_STATUSES.map((status) => (
        <div key={status}>
          <CustomTabButton
            onClick={() => setFilter('status', status === selectedStatus ? '' : status)}
            selected={status === selectedStatus}
          >
            <span className="tr-flex tr-items-center tr-font-bold tr-text-lg">{_.startCase(status)}</span>
          </CustomTabButton>
        </div>
      ))}
    </div>
  );
}
