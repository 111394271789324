export const USER_STATUSES = ['active', 'inactive', 'disabled', 'offboarded'] as const;
export const USER_TYPES = ['employee', 'contractor', 'customer', 'system'] as const;
export const INVITATION_STATUSES = ['pending', 'sent', 'expired'] as const;
export const LINK_STATUSES = ['linked', 'unlinked'] as const;

export const USER_FILTERS_KEYS = [
  'team',
  'department',
  'role',
  'title',
  'manager',
  // User information
  'status', // User status
  'type',
  'invitation_status',
  'link_status',
] as const;

export const USER_GROUP_BY_OPTIONS = ['user', 'team', 'department', 'role', 'title'] as const;
