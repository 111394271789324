import React, {useEffect} from 'react';
import {OrganizationSummaryResponse, useOrganizationSummary} from '../../hooks/useOrganizationSummary';
import {UserGroupByKey} from '../../types';
import {useUserFilters} from '../../UserFiltersContext';

const SUMMARY_GROUP_BY_BUTTONS: {
  groupByKey: UserGroupByKey;
  propName: keyof OrganizationSummaryResponse;
  label: string;
}[] = [
  {
    groupByKey: 'user',
    propName: 'users_count',
    label: 'Users',
  },
  {
    groupByKey: 'team',
    propName: 'teams_count',
    label: 'Teams',
  },
  {
    groupByKey: 'department',
    propName: 'departments_count',
    label: 'Departments',
  },
  {
    groupByKey: 'role',
    propName: 'roles_count',
    label: 'Roles',
  },
  {
    groupByKey: 'title',
    propName: 'titles_count',
    label: 'Titles',
  },
];

type SummaryGroupByButtonProps = {
  onClick: () => void;
  value: number;
  label: string;
  selected: boolean;
};

const SummaryGroupByButton = (props: SummaryGroupByButtonProps) => {
  const {onClick, value, label, selected} = props;

  let wrapperClassName =
    'tr-flex tr-flex-col tr-px-3 tr-py-2.5 hover:tr-bg-[#C2E5FF33] rounded-xl tr-min-w-[150px] tr-text-trustle-navy tr-cursor-pointer';

  if (selected) {
    wrapperClassName += ' tr-bg-[#E9F4FD]';
  }

  return (
    <div className={wrapperClassName} onClick={onClick}>
      <label className="tr-mb-0 tr-font-bold tr-text-sm md:tr-text-xl lg:tr-text-3xl xl:tr-text-6xl tr-cursor-pointer">
        {value.toLocaleString()}
      </label>
      <label className="tr-text-md tr-font-medium tr-cursor-pointer">{label}</label>
    </div>
  );
};

export function SummaryGroupBy() {
  const {filters, clearFilters} = useUserFilters();
  const {data, loading, getOrganizationSummary} = useOrganizationSummary();

  useEffect(() => {
    void getOrganizationSummary();
  }, []);

  if (loading || !data) {
    return <>Loading...</>;
  }

  return (
    <div className="tr-flex align-items-center rounded-2xl tr-shadow-md tr-border-solid tr-border-[2px] tr-border-gray-100 tr-p-5 tr-gap-4">
      {SUMMARY_GROUP_BY_BUTTONS.map(({groupByKey, propName, label}, index) => (
        <>
          <SummaryGroupByButton
            key={propName}
            onClick={() => {
              clearFilters({key: 'group_by', value: groupByKey});
            }}
            value={data[propName]}
            label={label}
            selected={filters?.group_by === groupByKey}
          />
          {index === 0 && <div className="tr-w-[1px] tr-h-[96px] relative tr-bg-gray-300" />}
        </>
      ))}
    </div>
  );
}
