import {SelectItem, UserFilterKey} from '../types';
import {useAdvancedFilters} from './useAdvancedFilters';
import {useEffect, useState} from 'react';

export type FilterConfig = {
  title: string;
  options: SelectItem[];
  isMulti?: boolean;
};

const DEFAULT_FILTER_OPTIONS = {
  invitation_status: {
    title: 'Invitation Status',
    options: [
      {label: 'Pending', value: 'pending'},
      {label: 'Sent', value: 'sent'},
      {label: 'Expired', value: 'expired'},
    ],
  },
  link_status: {
    title: 'Link Status',
    options: [
      {
        label: 'Linked',
        value: 'linked',
      },
      {
        label: 'Unlinked',
        value: 'unlinked',
      },
    ],
  },
  type: {
    title: 'Type',
    options: [
      {
        label: 'Employee',
        value: 'employee',
      },
      {
        label: 'Contractor',
        value: 'contractor',
      },
      {
        label: 'System',
        value: 'system',
      },
      {
        label: 'Customer',
        value: 'customer',
      },
    ],
  },
  status: {
    title: 'User Status',
    options: [
      {
        label: 'Active',
        value: 'active',
      },
      {
        label: 'Inactive',
        value: 'inactive',
      },
      {
        label: 'Disabled',
        value: 'disabled',
      },
      {
        label: 'Offboarded',
        value: 'offboarded',
      },
    ],
  },
  team: {
    title: 'Team',
    options: [],
  },
  department: {
    title: 'Department',
    options: [],
  },
  role: {
    title: 'Role',
    options: [],
  },
  title: {
    title: 'Title',
    options: [],
  },
  manager: {
    title: 'Manager',
    options: [],
  },
};

export function useFiltersConfigMap(): {
  [key in UserFilterKey]: FilterConfig;
} {
  const {data: advancedFilters, fetchAdvancedFilters, loading: advancedFiltersLoading = true} = useAdvancedFilters();
  const [filtersOptionsMap, setFiltersOptionsMap] = useState<any>({...DEFAULT_FILTER_OPTIONS});

  useEffect(() => {
    void fetchAdvancedFilters();
  }, []);

  const mapToOption = (data: {[key: string]: string}[]) => {
    return data.map((d) => ({label: Object.values(d)[0], value: Object.keys(d)[0]}));
  };

  useEffect(() => {
    if (!advancedFiltersLoading && advancedFilters) {
      const {teams, titles, roles, managers, departments} = advancedFilters;

      setFiltersOptionsMap({
        ...DEFAULT_FILTER_OPTIONS,
        ...{
          team: {
            title: 'Team',
            options: mapToOption(teams),
          },
          department: {
            title: 'Department',
            options: mapToOption(departments),
          },
          role: {
            title: 'Role',
            options: mapToOption(roles),
          },
          title: {
            title: 'Title',
            options: mapToOption(titles),
          },
          manager: {
            title: 'Manager',
            options: mapToOption(managers),
          },
        },
      });
    }
  }, [advancedFilters, advancedFiltersLoading]);

  return {
    ...filtersOptionsMap,
  };
}
