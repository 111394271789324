import React, {createContext, PropsWithChildren, useContext} from 'react';
import useUrlSyncPaginationAndFilters, {QueryFiltersAndPagination} from './hooks/useUrlSyncPaginationAndFilters';
import _ from 'lodash';

type UserFiltersContextType = {
  filters: QueryFiltersAndPagination;
  setFilter: (key: keyof QueryFiltersAndPagination, value: any) => void;
  setFilters: (filters: QueryFiltersAndPagination) => void;
  clearFilters: (filter?: {key: keyof QueryFiltersAndPagination; value: any}) => void;
};

const UserFiltersContext = createContext<UserFiltersContextType | undefined>(undefined);

type UsersFilterProviderProps = PropsWithChildren<{}>;

const DEFAULT_FILTERS: QueryFiltersAndPagination = {
  status: 'active',
  group_by: 'user',
  page: 1,
  size: 10,
};

export const UsersFilterProvider: React.FC<UsersFilterProviderProps> = ({children}) => {
  const [filters, setFilters] = useUrlSyncPaginationAndFilters(DEFAULT_FILTERS);

  // Clear all filters except for page, size, and group_by
  const clearFilters = (filter?: {key: keyof QueryFiltersAndPagination; value: any}) => {
    let toSet;

    if (filter && filter.key === 'group_by' && filter.value === 'user') {
      toSet = DEFAULT_FILTERS;
    } else {
      toSet = {..._.omit(DEFAULT_FILTERS, ['status']), group_by: filters.group_by};
    }

    // Clear with new filter
    if (filter) {
      toSet = {...toSet, [filter.key]: filter.value};
    }

    setFilters(toSet);
  };

  return (
    <UserFiltersContext.Provider
      value={{
        filters,
        setFilter: (key: keyof QueryFiltersAndPagination, value: any) => {
          if (!value) {
            const toSet = {...filters};
            delete toSet[key];
            setFilters(toSet);
          } else {
            setFilters({...filters, [key]: value});
          }
        },
        setFilters,
        clearFilters,
      }}
    >
      {children}
    </UserFiltersContext.Provider>
  );
};

export const useUserFilters = () => {
  const context = useContext(UserFiltersContext);
  if (context === undefined) {
    throw new Error('useUserFilters must be used within a UsersFilterProvider');
  }
  return context;
};
