import React, {useEffect, useState} from 'react';
import {Button, IconButton} from '@trustle/component-library';
import {FiltersSection} from './internal/FiltersSection';
import {AddFiltersSection} from './internal/AddFiltersSection';
import {SelectItem, FilterToApply, UserFilterKey} from '../../types';
import {useUserFilters} from '../../UserFiltersContext';
import {QueryFiltersAndPagination} from '../../hooks/useUrlSyncPaginationAndFilters';
import _ from 'lodash';
import {useFiltersConfigMap} from '../../hooks/useUserFiltersConfigMap';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export function FiltersSidePanel({isOpen, onClose}: Props) {
  const {filters, setFilters: setAppliedFilters} = useUserFilters();
  const [filtersToApply, setFiltersToApply] = useState<FilterToApply[]>([]);
  const FILTERS_CONFIG_MAP = useFiltersConfigMap();

  // Initialize filtersToApply
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {page: _page, size: _size, group_by: _group_by, ...restFilters} = filters;

    const initialFilters = Object.entries(restFilters).map(([key, value]) => {
      const isMulti = FILTERS_CONFIG_MAP[key as UserFilterKey].isMulti;

      if (isMulti) {
        const filterValues = (value as string).split(',');
        return {
          key: key as UserFilterKey,
          value: filterValues.map((value) => ({
            value,
            label: '', // Label should be resolved based on filter options
          })),
        };
      }

      return {
        key: key as UserFilterKey,
        value: {
          value: value as string,
          label: '', // Label should be resolved based on filter options
        },
      };
    });

    setFiltersToApply(initialFilters);
  }, [filters, isOpen]);

  const handleAddFilter = (key: UserFilterKey) => {
    setFiltersToApply([
      ...filtersToApply,
      {
        key,
        value: undefined,
      },
    ]);
  };

  const handleRemoveFilter = (key: UserFilterKey) => {
    setFiltersToApply(filtersToApply.filter((filter) => filter.key !== key));
  };

  const toggleFilterSelection = (key: UserFilterKey) => {
    if (filtersToApply.map((filter) => filter.key).includes(key)) {
      handleRemoveFilter(key);
    } else {
      handleAddFilter(key);
    }
  };

  const updateFilters = (key: UserFilterKey, selected: SelectItem[] | SelectItem) => {
    const filterToUpdateIndex = filtersToApply.findIndex((filter) => filter.key === key);
    const newFilters = [...filtersToApply];

    newFilters[filterToUpdateIndex] = {
      key,
      value: selected,
    };

    setFiltersToApply(newFilters);
  };

  const handleApplyFilters = () => {
    const toApply = filtersToApply.reduce((acc, curr) => {
      const {key, value} = curr;

      if (!value || (_.isArray(value) && value.length === 0)) {
        return acc;
      }

      acc[key] = _.isArray(value) ? value.map((item) => item.value).join(',') : value.value;

      return acc;
    }, {} as QueryFiltersAndPagination);

    const {page, size, group_by} = filters;
    setAppliedFilters({page, size, group_by, ...toApply});
  };

  return (
    <>
      {/* Overlay */}
      {isOpen && <div className="tr-fixed tr-inset-0 tr-bg-black tr-bg-opacity-50 tr-z-40" onClick={onClose} />}

      {/* Drawer Panel */}
      <div
        className={`tr-fixed tr-top-0 tr-right-0 tr-h-full tr-bg-white tr-shadow-lg tr-z-50 tr-transform tr-transition-transform ${
          isOpen ? 'tr-translate-x-0' : 'tr-translate-x-full'
        }`}
      >
        <div className="tr-flex tr-justify-between tr-py-3 tr-px-3.5 tr-bg-trustle-royal tr-items-center">
          <h3 className="tr-text-white tr-mb-0">Advanced Filters</h3>
          <IconButton onClick={onClose} variant="ternary" icon="cancel" />
        </div>
        <div className="tr-h-full">
          <div className="tr-flex tr-gap-4 tr-h-full">
            <div className="tr-flex tr-flex-col tr-w-[500px] tr-bg-[#F8F9FC] tr-py-3 tr-px-3.5">
              <FiltersSection
                filters={filtersToApply}
                removeFilter={handleRemoveFilter}
                updateFilters={updateFilters}
              />
              {!filtersToApply.length ? (
                <p className="tr-p-16 tr-text-center tr-text-md tr-font-normal">
                  This view doesn't have any advanced filters. Select a filter to begin.
                </p>
              ) : (
                <div className="tr-flex tr-ml-auto tr-gap-2">
                  <Button variant="secondary">Save Filters View</Button>
                  <Button variant="primary" onClick={handleApplyFilters}>
                    Apply
                  </Button>
                </div>
              )}
            </div>

            <AddFiltersSection toggleFilterSelection={toggleFilterSelection} filters={filtersToApply} />
          </div>
        </div>
      </div>
    </>
  );
}
