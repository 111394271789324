import NoData from '../../../../../components/NoData';
import React from 'react';

type Props = {
  text: string;
  description?: string;
  status: 'no-data' | 'loading';
};

const TABLE_STATUS_TO_RUSSEL = {
  'no-data': '/russel-confused.svg',
  loading: '/russel-thinking.svg',
};

export function TableStatus({text, description, status}: Props) {
  return (
    <NoData src={TABLE_STATUS_TO_RUSSEL[status]}>
      <div className="body5 mt-3">{text}</div>
      {description && (
        <div className="body5 text-muted mt-2" style={{width: '250px', display: 'inline-grid'}}>
          {description}
        </div>
      )}
    </NoData>
  );
}
