import {useState} from 'react';
import axios from 'axios';

export type OrganizationSummaryResponse = {
  users_count: number;
  systems_count: number;
  teams_count: number;
  departments_count: number;
  roles_count: number;
  titles_count: number;
};

export function useOrganizationSummary() {
  const [data, setData] = useState<OrganizationSummaryResponse>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const BASE_URL = '/api/v2/organization_summary';

  const getOrganizationSummary = async () => {
    setLoading(true);
    try {
      const response = await axios.get<OrganizationSummaryResponse>(BASE_URL);
      const responseData = response.data;
      setData(responseData);
    } catch (err: any) {
      // TODO: Implement handling error
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return {data, loading, error, getOrganizationSummary};
}
