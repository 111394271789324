import {useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import _ from 'lodash';
import {UserFilterKey, UserGroupByKey} from '../types';

export type QueryFiltersAndPagination = {
  [key in UserFilterKey]?: any;
} & {
  group_by?: UserGroupByKey;
  page?: number;
  size?: number;
};

export function useUrlSyncPaginationAndFilters(initialFilters: QueryFiltersAndPagination = {}) {
  const location = useLocation();
  const history = useHistory();

  // Parse filters from URL search parameters
  const getFiltersFromUrl = () => {
    const params = new URLSearchParams(location.search);
    const filters: any = {};
    params.forEach((value, key) => {
      if (key === 'page' || key === 'size') {
        // Convert pagination-related params to numbers
        filters[key] = parseInt(value, 10) || 1; // Default to 1 if parsing fails
      } else {
        // Keep other parameters as strings (or convert as needed)
        filters[key] = value;
      }
    });

    return !_.isEmpty(filters) ? filters : initialFilters;
  };

  const [filters, setFilters] = useState(getFiltersFromUrl);

  // Update URL when filters change
  useEffect(() => {
    history.replace({search: new URLSearchParams(filters as any).toString()});
  }, [filters]);

  // Sync filters state when URL changes
  useEffect(() => {
    const urlFilters = getFiltersFromUrl();
    // Only update filters if they differ from the current state
    if (!_.isEqual(filters, urlFilters)) {
      setFilters(urlFilters);
    }
  }, [location.search]);

  return [filters, setFilters];
}

export default useUrlSyncPaginationAndFilters;
