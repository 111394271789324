import _ from 'lodash';
import {Icon, SystemIcons, Tooltip, UserInfo} from '@trustle/component-library';
import {Link} from 'react-router-dom';
import {formatDateFromNow, formatFullDate, formatUserName} from '../../../../../../lib';
import React from 'react';
import {useRootStore} from '../../../../../../lib/hooks';
import {SourceOfTruthE} from '../../../../../../types';
import {SystemIconsTypesEnum} from '@trustle/component-library/dist/types';
import {observer} from 'mobx-react';
import {InvitationStatus, UserStatus} from '../../types';
import moment from 'moment/moment';

export const USER_COLUMNS = [
  {
    dataField: 'id',
    text: '',
    hidden: true,
  },
  {
    dataField: 'invitation_status',
    formatter: (_: any, user: {invitation_status: InvitationStatus; user_status: UserStatus; invited_at: string}) => {
      const {invitation_status, user_status, invited_at} = user;

      const invitationString = `Invited on ${moment(invited_at).format('M/D/Y hh:mma')}`;

      const statusClassMap: Record<UserStatus, string> = {
        active: 'tr-bg-status-success',
        inactive: 'tr-bg-status-pending',
        disabled: 'tr-bg-status-disabled',
        offboarded: 'tr-bg-status-failed',
      };

      if (!['disabled', 'offboarded', 'active'].includes(user_status) && invited_at) {
        if (invitation_status === 'expired') {
          return (
            <span data-testid="emailExpired">
              <Icon type="emailExpired" title={`${invitationString}, it is expired now`} size="sm" />
            </span>
          );
        }
        return (
          <span data-testid="emailPending">
            <Icon type="emailPending" title={`${invitationString}`} size="sm" />
          </span>
        );
      }
      return (
        <div
          data-testid={`status${user_status}`}
          className="tr-flex tr-items-center tr-space-x-2 tr-w-6 tr-justify-center"
          title={`User Status: ${user_status}`}
        >
          <div className={`tr-w-[9px] tr-h-[9px] ${statusClassMap[user_status]} tr-rounded-full`}></div>
        </div>
      );
    },
    headerStyle: {width: '2.2em', paddingLeft: '8px', paddingRight: '8px', textAlign: 'center'},
    style: {width: '2.2em', paddingLeft: '8px', paddingRight: '8px', textAlign: 'center'},
  },
  {
    dataField: 'linked',
    formatter: (_: any, user: any) => {
      return (
        <span data-testid={`statusLink${user.email}`} className="tr-text-trustle-link">
          {!user.linked && <Icon title="User is not linked to any System" type="unlinkedUser" size="sm" />}
        </span>
      );
    },
    headerStyle: {width: '2.3em', paddingLeft: '8px', paddingRight: '8px', textAlign: 'center'},
    style: {width: '2.3em', paddingLeft: '8px', paddingRight: '8px', textAlign: 'center'},
  },
  {
    text: 'Name',
    formatter: (_: any, user: any) => (
      <div id="userNameCol" data-testid={`name${user.email}`}>
        <UserInfo type={user.type} isOrgOwner={user.is_org_owner} className="tr-text-trustle-link">
          <Link className="" to={`/users/${user.id}/current`}>
            {formatUserName(user)}
          </Link>
        </UserInfo>
      </div>
    ),
    headerStyle: {width: '13rem', textAlign: 'left', paddingLeft: '8px'},
    style: {width: '13rem', textAlign: 'left', paddingLeft: '8px'},
  },
  {
    dataField: 'manager',
    text: 'Manager',
    formatter: (_: any, user: any) => {
      const {manager} = user;
      if (!manager) {
        return <Icon type="emptyData" title="No Manager" size="sm" />;
      }
      return <Link to={`/users/${manager?.id}`}>{formatUserName(manager)}</Link>;
    },
    headerStyle: {width: '10rem', textAlign: 'left'},
    style: {width: '10rem', textAlign: 'left'},
  },
  {
    dataField: 'last_login',
    text: 'Last Login',
    formatter: (_: any, user: any) => {
      const {last_login} = user;
      return !last_login ? (
        <Icon type="emptyData" title="Never" size="sm" />
      ) : (
        <Tooltip position="top" content={formatFullDate(last_login)}>
          {formatDateFromNow(last_login)}
        </Tooltip>
      );
    },
    headerStyle: {width: '7rem', textAlign: 'left'},
    style: {width: '7rem', textAlign: 'left'},
  },
  {
    dataField: 'department',
    headerStyle: {width: '13em', textAlign: 'left', overFlow: 'visible'},
    style: {width: '13em', textAlign: 'left'},
    formatter: (_: string, user: any) => {
      const {department, remoteRole, title} = user;

      if (!department && !remoteRole && !title) {
        return <Icon type="emptyData" title="No data" size="sm" />;
      }

      return (
        <div className="tr-my-1 tr-flex-col">
          <div className="tr-font-bold">{department ?? '[No department defined]'}</div>
          <div>{remoteRole ?? '[No role defined]'}</div>
          <div className="tr-text-gray-600">{title ?? '[No title defined]'}</div>
        </div>
      );
    },
    headerFormatter: (_column: any, _index: number) => {
      return (
        <div className="tr-flex">
          <div className="tr-py-1">
            <span className="tr-mx-1">Dept</span>
          </div>
          <div className="tr-py-1">{'/'}</div>
          <div className="tr-py-1">
            <span className="tr-mx-1">Role</span>
          </div>
          <div className="tr-py-1">{'/'}</div>
          <div className="tr-py-1">
            <span className="tr-mx-1">Title</span>
          </div>
        </div>
      );
    },
  },
  {
    dataField: 'source_of_truth',
    text: 'Authority',
    headerStyle: {width: '4rem'},
    style: {width: '4rem'},
    formatter: (_cell: any, user: any) => {
      const ObservableAuthority = observer((props: {user: any}) => {
        const {org} = useRootStore();
        const {user} = props;

        const isTrustle = _.defaultTo(user.source_of_truth, SourceOfTruthE.TRUSTLE) === SourceOfTruthE.TRUSTLE;
        const icon = isTrustle ? (
          <Icon type="Trustle" title="Trustle" forcedSize={32} />
        ) : (
          <SystemIcons name={org.idpSettings?.service as SystemIconsTypesEnum} size="sm" />
        );

        return (
          <div className="tr-flex tr-justify-center tr-py-4">
            <a href="#" title={org.orgUsersAuthorityLabel}>
              {icon}
            </a>
          </div>
        );
      });

      return <ObservableAuthority user={user} />;
    },
  },
  // TODO: Implement actions
  // {
  //   classes: 'show-on-hover',
  //   headerStyle: {width: '7rem', paddingRight: '4px'},
  //   style: {width: '7rem', paddingRight: 0},
  //   formatter: (_: any, user: any) => {
  //     return <div className="action-buttons tr-flex tr-flex-row tr-items-center tr-gap-1">Actions</div>;
  //   },
  // },
] as any;
