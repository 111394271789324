import React, {Fragment} from 'react';
import {Filter} from './Filter';
import {SelectItem, FilterToApply, UserFilterKey} from '../../../types';
import {useFiltersConfigMap} from '../../../hooks/useUserFiltersConfigMap';

type Props = {
  filters: FilterToApply[];
  removeFilter: (key: UserFilterKey) => void;
  updateFilters: (key: UserFilterKey, selected: SelectItem[] | SelectItem) => void;
};

export function FiltersSection({filters, removeFilter, updateFilters}: Props) {
  const FILTERS_CONFIG_MAP = useFiltersConfigMap();

  return (
    <div className="tr-flex tr-flex-col tr-gap-4 ">
      <h4 className="tr-mb-0 tr-text-trustle-navy text-lg">Applied Filters</h4>

      {filters.map(({key, value}) => (
        <Fragment key={key}>
          <Filter
            key={key}
            title={FILTERS_CONFIG_MAP[key].title}
            selected={value}
            setSelected={(selected) => {
              updateFilters(key, selected);
            }}
            removeFilter={() => removeFilter(key)}
            options={FILTERS_CONFIG_MAP[key].options}
            isMulti={FILTERS_CONFIG_MAP[key].isMulti}
          />
        </Fragment>
      ))}
    </div>
  );
}
