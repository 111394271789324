import React, {useEffect, useState} from 'react';
import {Table} from '@trustle/component-library';
import {useTeams} from '../../hooks/useTeams';
import {TableStatus} from '../../common/TableStatus';
import {useTeamsColumns} from './useTeamsColumns';

export function TeamsSummaryTable() {
  const {data: listTeamsResponse, loading, fetchTeams} = useTeams();
  const COLUMNS = useTeamsColumns();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50);

  useEffect(() => {
    void fetchTeams({page, size});
  }, [page, size]);

  if (loading || !listTeamsResponse) {
    return <TableStatus text="Loading users..." status="loading" description="Please stand by" />;
  }

  const {teams, total} = listTeamsResponse;

  return (
    <Table
      data={teams}
      columns={COLUMNS}
      tableKey="teams-summary-table"
      totalSize={total}
      sizePerPage={size}
      page={page}
      remote={{
        filter: true,
        pagination: true,
        sort: false,
        cellEdit: false,
      }}
      onSizePerPageChange={(size) => {
        setSize(size);
        return {};
      }}
      onTableChange={(_type: string, {page}: {page: number}) => {
        setPage(page);
      }}
      showEmptyElement={<TableStatus status="no-data" text="No teams found" />}
    />
  );
}
