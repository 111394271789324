import {Button, Icon, TextInput} from '@trustle/component-library';
import React, {useMemo} from 'react';
import {useUserFilters} from '../../UserFiltersContext';
import {QueryFiltersAndPagination} from '../../hooks/useUrlSyncPaginationAndFilters';
import {ItemIconTypesEnum} from '@trustle/component-library/dist/types';
import {FilterConfig, useFiltersConfigMap} from '../../hooks/useUserFiltersConfigMap';
import {UserFilterKey} from '../../types';

export function HeaderSearch() {
  const {filters, clearFilters} = useUserFilters();
  const filtersConfigMap = useFiltersConfigMap();

  const {title, icon} = getTitleAndIcon(filters, filtersConfigMap);

  const showBackToGroupBy = useMemo(() => {
    const {group_by} = filters;
    return group_by && group_by !== 'user' && filters[group_by];
  }, [filters]);

  return (
    <div className="tr-flex tr-items-center tr-gap-3 tr-bg-[#F8F9FC] tr-py-3 tr-px-2.5">
      {showBackToGroupBy && (
        <Button
          onClick={() => {
            clearFilters();
          }}
          variant="ternary"
        >
          <span className="tr-flex tr-items-center tr-gap-1">
            <Icon type="chevronLeft" size="sm" />
            Go back
          </span>
        </Button>
      )}
      <Icon type={icon} size="md" />
      <h2 className="tr-mb-0">{title}</h2>
      {(filters.group_by === 'user' || showBackToGroupBy) && (
        <TextInput placeholder="Search for users" className="tr-pb-0 tr-max-w-[600px] tr-h-[40px]" />
      )}
    </div>
  );
}

function getTitleAndIcon(
  filters: QueryFiltersAndPagination,
  filtersConfigMap: Record<UserFilterKey, FilterConfig>
): {title: string; icon: ItemIconTypesEnum} {
  const {group_by, team, department, role, title} = filters;

  const getLabel = (key: UserFilterKey) => {
    return filtersConfigMap[key].options.find((o) => o.value === filters[key])?.label;
  };

  switch (group_by) {
    case 'team':
      return {
        title: team ? `Team: ${getLabel('team')}` : 'Teams',
        icon: 'team',
      };
    case 'department':
      return {
        title: department ? `Department: ${getLabel('department')}` : 'Departments',
        icon: 'project',
      };
    case 'role':
      return {
        title: role ? `Role: ${getLabel('role')}` : 'Roles',
        icon: 'roles',
      };
    case 'title':
      return {
        title: title ? `Title: ${getLabel('title')}` : 'Titles',
        icon: 'project',
      };
    default:
      return {
        title: 'Users',
        icon: 'groupPeople',
      };
  }
}
