import {Table} from '@trustle/component-library';
import React, {useEffect} from 'react';
import {useUsers} from '../../hooks/useUsers';
import {useUserFilters} from '../../UserFiltersContext';
import {TableStatus} from '../../common/TableStatus';
import {USER_COLUMNS} from './userColumns';

const TABLE_KEY = 'users-table';

export function UsersTable() {
  const {data: listUsersResponse, loading, fetchUsers} = useUsers();
  const columns = USER_COLUMNS;

  const {filters, setFilter} = useUserFilters();

  useEffect(() => {
    void fetchUsers({...filters});
  }, [filters]);

  const handleTableChange = async (_type: string, {page}: {page: number}) => {
    setFilter('page', page);
  };

  const handleSizePerPageChange = (size: number) => {
    setFilter('size', size);
  };

  if (loading || !listUsersResponse) {
    return <TableStatus text="Loading users..." status="loading" description="Please stand by" />;
  }

  const {users, total} = listUsersResponse;

  return (
    <Table
      data={users}
      columns={columns}
      tableKey={TABLE_KEY}
      totalSize={total}
      sizePerPage={filters.size}
      page={filters.page}
      remote={{
        filter: true,
        pagination: true,
        sort: false,
        cellEdit: false,
      }}
      onSizePerPageChange={(size) => {
        handleSizePerPageChange(size);
        return {};
      }}
      onTableChange={handleTableChange}
      showEmptyElement={
        <TableStatus status="no-data" text="No users found" description="Try with different filter criteria" />
      }
    />
  );
}
