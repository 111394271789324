import {useState} from 'react';
import axios from 'axios';
import {InvitationStatus, UserStatus, UserType} from '../types';

type ListUsersResponse = {
  users: any[]; // TODO: define type
  total: number;
  page: number;
  size: number;
  pages: number;
};

type PaginationParams = {
  page?: number;
  size?: number;
};

// TODO: implement params once peryton API is ready
type ListUsersParams = {
  status?: UserStatus;
  type?: UserType;
  invitation_status?: InvitationStatus;
  team?: string;
} & PaginationParams;

export function useUsers() {
  const [data, setData] = useState<ListUsersResponse>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const BASE_URL = '/api/v2/users';

  const fetchUsers = async (params?: ListUsersParams) => {
    // TODO: implement all params
    const parsedParams: any = {
      page: params?.page || 1,
      size: params?.size || 50,
    };

    if (params?.status) {
      parsedParams.status = params.status;
    }

    if (params?.type) {
      parsedParams.type = params.type;
    }

    if (params?.invitation_status) {
      parsedParams.invitation_status = params.invitation_status;
    }

    if (params?.team) {
      parsedParams.team_id = params.team;
    }

    const queryString = new URLSearchParams(parsedParams).toString();

    setLoading(true);
    try {
      const response = await axios.get<ListUsersResponse>(`${BASE_URL}?${queryString}`);
      const responseData = response.data;
      setData(responseData);
    } catch (err: any) {
      // TODO: Implement handling error
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return {data, loading, error, fetchUsers};
}
